import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import MarkdownContent from "../components/markdown"
import { Breadcrumb } from "react-bootstrap"
import Img from "gatsby-image"

const PostPage = ({ data }) => (
  <Layout>
    <div className={"post-page py-5 pb-0 light-bg"}>
      <div className={"container"}>
        <Breadcrumb>
          <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
          <Breadcrumb.Item href="/blog">Blog</Breadcrumb.Item>
          <Breadcrumb.Item active>
            {data.markdownRemark.frontmatter.title}
          </Breadcrumb.Item>
        </Breadcrumb>
        <h1 className={"text-center mb-5"}>
          {data.markdownRemark.frontmatter.title}
        </h1>
        <div className="mb-5">
          <Img
            fluid={
              data.markdownRemark.frontmatter.coverPhoto.childImageSharp.fluid
            }
          />
        </div>
        <MarkdownContent content={data.markdownRemark.frontmatter.content} />
      </div>
    </div>
  </Layout>
)
export default PostPage

export const query = graphql`
  query PostPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        seoTitle
        metaDescription
        coverPhoto {
          childImageSharp {
            fluid(maxWidth: 960) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        gist
        publishedAt
        content
      }
    }
  }
`
